/**
 * Created by decipaliz on 22.05.2024
 */

var PrizeCalendarTileView = cc.Node.extend({
    ctor: function (tile) {
        this._super();

        this.tile = tile;

        this.setAnchorPoint(0.5, 0.5);

        cleverapps.UI.onClick(this, this.onTileClick.bind(this), {
            onDoubleClick: cleverapps.config.debugMode && this.onTileDoubleClick.bind(this)
        });

        this.createBackgrounds();
        this.setContentSize2(this.outerBg.getContentSize());

        this.createComponent();

        this.createRewards();

        tile.onReceiveTile = this.createListener(this.onReceiveTile, this);
        tile.onChangeState = this.createListener(this.onChangeState, this);
        tile.onOpenTile = this.createListener(this.onOpenTile, this);
    },

    isLast: function () {
        return this.tile.isLast;
    },

    onChangeState: function () {
        if (cleverapps.config.name === "riddles") {
            if (!this.isLast() && this.tile.state === PrizeCalendarTile.STATE_ACTIVE) {
                this.spinAnimation();
            }
        }

        if (this.component) {
            this.component.removeFromParent();
            this.component = undefined;
        }

        this.createComponent();
    },

    createComponent: function () {
        this.component = this.tile.getComponent();

        this.component.setAnchorPoint(0.5, 0.5);

        this.component.setContentSize(this.getContentSize());
        this.component.setPosition(this.width / 2, this.height / 2);

        if (["heroes", "riddles"].includes(cleverapps.config.name) && this.component.titleBg) {
            cleverapps.UI.onClick(this.component.titleBg, this.onTileClick.bind(this), {
                onDoubleClick: cleverapps.config.debugMode && this.onTileDoubleClick.bind(this)
            });
        }

        this.addChild(this.component, 10);
    },

    setArrow: function (arrow) {
        this.arrow = arrow;
    },

    createBackgrounds: function () {
        this.outerBg = this.createBg("outerBg");

        if (cleverapps.config.name !== "heroes") {
            this.innerBg = this.createBg("innerBg");
        }
    },

    createBg: function (bgType) {
        var bg;
        var styles = cleverapps.styles.PrizeCalendarTileView;

        if (cleverapps.config.name === "riddles") {
            bg = cleverapps.UI.createScale9Sprite(this.getScale9BgFrame(bgType), cleverapps.UI.Scale9Rect.TwoPixelXY);
            bg.setContentSize2(styles[bgType]);
        } else if (cleverapps.config.name === "runes" && bgType === "outerBg") {
            bg = new cleverapps.Spine(bundles.prizecalendar.jsons.tile_bg);
            bg.setAnimation(0, "animation", true);
        } else {
            bg = new cleverapps.Spine(bundles.prizecalendar.jsons.tile_json);
            bg.setAnimation(0, this.getTileAnimation(), true);
        }

        if (bg) {
            bg.setPositionRound(styles);
            this.addChild(bg);
        }

        return bg;
    },

    createRewards: function () {
        var styles = cleverapps.styles.PrizeCalendarTileView;
        if (cleverapps.config.name !== "riddles") {
            return;
        }

        if (this.isLast()) {
            this.rewards = new cc.Sprite(bundles.prizecalendar.frames.chest_png);
            this.rewards.setPositionRound(styles.rewards.chest);

            this.rays = AnimationsLibrary.rays(this.rewards, { skin: "white" });
        } else {
            this.rewards = new RewardsListComponent(this.tile.rewardsList, {
                fitToBox: styles.rewards.fitToBox,
                textMargin: -10,
                font: cleverapps.styles.FONTS.PRIZE_CALENDAR_REWARD
            });
            this.rewards.setPositionRound(styles.rewards);
        }

        this.innerBg.addChild(this.rewards);
    },

    onReceiveTile: function (rewardsList, callback) {
        this.component.onReceiveTile(rewardsList, callback);
    },

    onOpenTile: function (callback) {
        this.runningOpenAnimation = true;
        this.component.openTile(function () {
            this.runningOpenAnimation = false;
            callback();
        }.bind(this));
    },

    getTileAnimation: function () {
        var tileAnimation;
        switch (this.tile.state) {
            case PrizeCalendarTile.STATE_ACTIVE:
            case PrizeCalendarTile.STATE_WAITING:
                tileAnimation = "active_idle";
                break;
            case PrizeCalendarTile.STATE_RECEIVED:
                tileAnimation = "received_idle";
                break;
            default:
                tileAnimation = "locked_idle";
                break;
        }

        if (this.isLast() && this.tile.state !== PrizeCalendarTile.STATE_RECEIVED) {
            tileAnimation += "_last";
        }

        return tileAnimation;
    },

    getScale9BgFrame: function (bgType) {
        if (this.isLast()) {
            return bundles.prizecalendar.frames[bgType + "_chest"];
        }

        if ([PrizeCalendarTile.STATE_WAITING, PrizeCalendarTile.STATE_LOCKED].includes(this.tile.state)) {
            return bundles.prizecalendar.frames[bgType + "_locked"];
        }

        return bundles.prizecalendar.frames[bgType + "_unlocked"];
    },

    onTileClick: function () {
        if (this.runningOpenAnimation) {
            return;
        }

        if (this.tile.state === PrizeCalendarTile.STATE_LOCKED) {
            if (!this.tile.isLast && cleverapps.config.name === "riddles") {
                cleverapps.centerHint.createTextHint("PrizeCalendarTile.Locked");
            } else {
                cleverapps.tooltipManager.onClick(this, {
                    content: new RewardsListComponent(this.tile.rewardsList, {
                        font: cleverapps.styles.FONTS.PRIZE_CALENDAR_REWARD
                    }),
                    size: cleverapps.styles.PrizeCalendarTileView.rewardsTooltip,
                    location: cleverapps.UI.Tooltip.LOCATION_ABOVE
                });
            }
        } else {
            this.tile.onClick(this.tile);
        }
    },

    onTileDoubleClick: function () {
        if (this.runningOpenAnimation) {
            return;
        }

        this.tile.onTileDoubleClick();
    },

    spinAnimation: function () {
        if (!this.innerBg) {
            return;
        }

        var baseScale = this.innerBg.scaleX;

        this.innerBg.runAction(new cc.Sequence(
            new cc.ScaleTo(0.3, 0, this.innerBg.scaleY).easing(cc.easeIn(2)),
            new cc.CallFunc(function () {
                this.outerBg.removeFromParent();
                this.outerBg = this.createBg("outerBg");

                this.arrow.removeFromParent();
                var window = cleverapps.windows.currentWindow();

                window.createArrow(this);

                this.outerBg.setLocalZOrder(-1);
            }.bind(this)),
            new cc.ScaleTo(0.3, baseScale, this.innerBg.scaleY).easing(cc.easeOut(2))
        ));
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PRIZE_CALENDAR_TITLE: {
        name: "nostroke",
        size: 32,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.REWARDS_TEXT_STROKE
    },

    PRIZE_CALENDAR_REWARD: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    }
});

cleverapps.styles.PrizeCalendarTileView = {
    x: { align: "center" },
    y: { align: "center" },

    rewardsTooltip: {
        width: 360,
        height: 180
    }
};
