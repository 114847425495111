/**
 * Created by Denis Kuzin on 14 june 2024
 */

var ViewFactory = function (component, cellView) {
    var componentView;

    if (component instanceof ColorComponent && !component.cell.useCustomView()) {
        componentView = new ColorComponentView(component, cellView);
    } else if (component instanceof GoalCoefComponent) {
        componentView = new GoalCoefComponentView(component, cellView);
    } else if (component instanceof DecoratorComponent) {
        componentView = new DecoratorComponentView(component, cellView);
    } else if (component instanceof BigComponent) {
        componentView = new BigComponentView(component, cellView);
    } else if (component instanceof EaterComponent) {
        componentView = new EaterComponentView(component, cellView);
    } else if (component instanceof LiveLineComponent) {
        componentView = new LiveLineComponentView(component, cellView);
    } else if (component instanceof SpecialShapeComponent) {
        componentView = new SpecialShapeComponentView(component, cellView);
    } else if (component instanceof MuffinComponent) {
        componentView = new MuffinComponentView(component, cellView);
    } else if (component instanceof MarkComponent) {
        componentView = new MarkComponentView(component, cellView);
    } else if (component instanceof ComboComponent) {
        componentView = new ComboComponentView(component, cellView);
    } else if (component instanceof SuperLinerComponent) {
        componentView = new SuperLinerComponentView(component, cellView);
    }

    if (componentView) {
        componentView.setPositionRound(cellView.width / 2, cellView.height / 2);
        componentView.setAnchorPoint(0.5, 0.5);
        cellView.addChild(componentView);

        component.view = componentView;
    }
};