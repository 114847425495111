/**
 * Created by andrey on 24.05.17.
 */

cleverapps.Tool = cleverapps.override(cleverapps.Tool, {
    UI: {
        windows: {
            canUpgradeHeroWindow: displayToolWindow(function () {
                new CanUpgradeHeroWindow();
            })
        },

        helpByFiveAttemptsWindow: displayToolWindow(function () {
            new HelpByFiveAttemptsWindow();
        })
    },

    game: {
        win: function () {
            if (Game.currentGame && cleverapps.scenes.getRunningScene() instanceof GameScene) {
                if (Game.currentGame.tutorial && !Game.currentGame.tutorial.finished) {
                    Game.currentGame.tutorial.finishAll();
                }

                var goals = Game.currentGame.goals;

                for (var type in goals.elements) {
                    goals.incGoal(type, goals.elements[type].target - goals.elements[type].amount);
                }

                Game.currentGame.counter.setTimeout(function () {}, 100);
                Game.currentGame.goalCounter.runStages();
            }
        },

        movesReset: function () {
            if (Game.currentGame) {
                Game.currentGame.setMoves(1, { silent: true });
            }
        },

        goalsOneMore: function () {
            if (Game.currentGame) {
                var goals = Object.values(Game.currentGame.goals.elements);

                goals.forEach(function (goal, index) {
                    var isLast = index === goals.length - 1;
                    var dt = Math.max(0, goal.target - goal.amount);
                    if (isLast) {
                        dt--;
                    }
                    dt && goal.inc(dt);
                });
                Game.currentGame.goalCounter.runStages();
            }
        },

        addBoosterCell: function () {
            if (Game.currentGame) {
                Game.currentGame.field.pushNextCell(BoosterCell);
            }
        },

        addTulpanCell: function () {
            if (Game.currentGame) {
                Game.currentGame.field.pushNextCell(TulpanCell);
            }
        },

        addMovesWindow: function () {
            cleverapps.scenes.getRunningScene().outOfMoves();
        },

        addClover: function () {
            if (Game.currentGame.getMissionType() !== undefined) {
                Game.currentGame.addClover(Game.currentGame.getMissionType(), 1);
            }
        },

        exclamations: ToolFolder.create(function () {
            var res = {};
            if (cleverapps.environment.isGameScene()) {
                res.shuffle = function () {
                    cleverapps.exclamation.show("message.Shuffle");
                };
                res.goals_done = function () {
                    cleverapps.exclamation.show("message.YouWin", Exclamation.Types.Congrats);
                };
                res.five_moves = function () {
                    cleverapps.exclamation.show("message.MovesLeft", Exclamation.Types.Warning);
                };
                res.hardlevel = function () {
                    cleverapps.exclamation.show("message.hardLevel", Exclamation.Types.Warning);
                };
                res.lets_go = function () {
                    cleverapps.exclamation.show("message.Letsgo");
                };
                res.tutorial_finish = function () {
                    cleverapps.exclamation.show("message.KeepGoing");
                };
                res.congratulation = function () {
                    var msg = cleverapps.Random.mathChoose(cleverapps.exclamation.getCongratulations());
                    cleverapps.exclamation.show(msg, Exclamation.Types.Congrats);
                };
            }
            return res;
        }),

        showUp: function () {
            var field = Game.currentGame.field;
            var fieldView = field.onGetView();
            var floorView = fieldView.floorView;

            fieldView.innerContainer.children.forEach(function (cellView) {
                cellView.setScaleY(0);
            });
            fieldView.hideGoals();

            if (cleverapps.config.name !== "riddles") {
                floorView.setVisible(false);
            } else {
                fieldView.setVisible(true);
                floorView.setVisible(true);

                fieldView.updateSize();
            }

            field.showUpAnimation(false, function () {});
        },

        hide: function () {
            Game.currentGame.field.trigger("hide", function () {
                Game.currentGame.field.onGetView().setScale(1);
            });
        }
    },

    starChest: {
        add20: function () {
            cleverapps.starChest.addStars(20);
        },

        reset: function () {
            cleverapps.starChest.addStars(-cleverapps.starChest.stars);
        }
    }
});