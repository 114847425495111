/**
 * Created by anatoly on 14.11.2024
 */

var LockedCalendarTileComponent = cc.Node.extend({
    ctor: function (tile) {
        this._super();

        this.tile = tile;
        this.createMarks();
    },

    isLast: function () {
        return this.tile.isLast;
    },

    createMarks: function () {
        if (cleverapps.config.name !== "riddles") {
            return;
        }

        var styles = cleverapps.styles.PrizeCalendarTileView;

        this.mark = new cleverapps.Spine(bundles.prizecalendar.jsons.padlock_json);
        this.mark.setAnimation(0, "idle", true);
        this.mark.setPositionRound(styles.mark);
        this.addChild(this.mark);
    },

    openTile: function (callback) {
        if (this.mark) {
            this.mark.setAnimation(0, "hide", false);
        }

        var actions = [
            function (f) {
                this.showUpReward(f);
            }.bind(this),

            function (f) {
                var bgChangeAnimations = this.getBgChangeAndIdleAnimations();

                if (bgChangeAnimations) {
                    this.changeBgAnimation(bgChangeAnimations, f);
                    return;
                }
                f();
            }.bind(this)
        ];

        new ActionPlayer(actions).play(callback);
    },

    showUpReward: function (callback) {
        if (!["heroes", "runes"].includes(cleverapps.config.name) || this.isLast()) {
            callback();
            return;
        }

        var scene = cleverapps.scenes.getRunningScene();

        var tileView = this.getParent();

        var bg = tileView.innerBg || tileView.outerBg;

        bg.replaceParentSamePlace(scene);
        bg.setLocalZOrder(scene.movingNode.getLocalZOrder() + 1);

        var rewardIcon = this.tile.rewardsList.rewards[0].getIcon();

        rewardIcon.setPosition(this.width / 2, this.height / 2 + 200);
        this.addChild(rewardIcon);

        rewardIcon.replaceParentSamePlace(scene);
        rewardIcon.setLocalZOrder(bg.getLocalZOrder() - 1);

        rewardIcon.setVisible(false);
        rewardIcon.runAction(new cc.Sequence(
            AnimationsLibrary.showUp(rewardIcon, { flash: true }),
            new cc.MoveBy(0.3, 0, -225),
            new cc.RemoveSelf(),
            new cc.CallFunc(function () {
                bg.replaceParentSamePlace(tileView);
                bg.setLocalZOrder(0);
            })
        ));

        if (cleverapps.config.name === "runes") {
            bg.setAnimation(0, "w_box_drop_prize");
            bg.setCompleteListener(callback);

            return;
        }

        callback();
    },

    getBgChangeAndIdleAnimations: function () {
        if (["heroes", "runes"].indexOf(cleverapps.config.name) === -1) {
            return;
        }

        return {
            animations: this.isLast() ? {
                animation: cleverapps.config.name === "heroes" ? "active_idle_last" : "to_active_last",
                idle: "active_idle_last"
            } : {
                animation: "to_active",
                idle: "active_idle"
            },
            bg: cleverapps.config.name === "heroes" ? this.getParent().outerBg : this.getParent().innerBg
        };
    },

    changeBgAnimation: function (bgChangeAnimations, callback) {
        var animations = bgChangeAnimations.animations;
        var bg = bgChangeAnimations.bg;

        bg.setAnimationAndIdleAfter(animations.animation, animations.idle);
        bg.setCompleteListener(callback);
    }
});