/**
 * Created by mac on 12/7/24
 */

cleverapps.override(cleverapps.EVENTS, {
    HELP_BY_FIVE_ATTEMPTS: "help_by_five_attemts",
    HELP_BY_ATTEMPTS_DAU: "help_by_attemts_dau",
    HELP_BY_ATTEMPTS_ONE_AND_MORE: "help_by_attemts_1_and_more",
    HELP_BY_ATTEMPTS_THREE_AND_MORE: "help_by_attemts_3_and_more",
    HELP_BY_ATTEMPTS_FIVE_AND_MORE: "help_by_attemts_5_and_more"
});