/**
 * Created by andrey on 01.08.2022
 */

var HideAnimations = function (f) {
    var game = this.game;
    var hideGoals = function (f) {
        if (this.infoPanel) {
            this.infoPanel.hide();
        } else {
            this.movesView.hide();
            this.goalsView.hide();
        }
        f();
    }.bind(this);

    new ActionPlayer([
        function (f) {
            if (game.outcome !== GameBase.OUTCOME_VICTORY) {
                hideGoals(f);
            } else {
                f();
            }
        },

        function (f) {
            if (this.fieldView.visible) {
                game.hideField(f);
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            if (game.hasDanger()) {
                this.dangerAnimationView.hide(f);
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            if (game.outcome !== GameBase.OUTCOME_VICTORY) {
                f();
                return;
            }
            this.goalsView.animateHighlight(f);
        }.bind(this),

        hideGoals,

        function (f) {
            if (this.hardLevelIcon) {
                this.hardLevelIcon.hide();
            }

            f();
        }.bind(this)
    ]).play(f);
};
