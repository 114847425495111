/**
 * Created by mac on 12/7/24
 */

var Match3HelpByAttemptsReporter = function (data) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.HELP_BY_ATTEMPTS_DAU);
    var amount = data[cleverapps.EVENTS.HELP_BY_FIVE_ATTEMPTS];
    if (amount >= 1) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.HELP_BY_ATTEMPTS_ONE_AND_MORE);
    }
    if (amount >= 3) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.HELP_BY_ATTEMPTS_THREE_AND_MORE);
    }
    if (amount >= 5) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.HELP_BY_ATTEMPTS_FIVE_AND_MORE);
    }
};