/**
 * Created by Denis Kuzin on 27 november 2024
 */

var SuperLinerComponent = function (cell) {
    this.cell = cell;

    this.occupiedCells = [];

    if (this.cell.algo.name === CombosLibrary.TYPE_SUPER_LINER) {
        BaseCell.neighbors.forEach(function (cell) {
            this.occupiedCells.push({
                x: this.cell.x + cell.x,
                y: this.cell.y + cell.y,
                dir: cell.x === 0 ? CombosLibrary.LINER_DIRECTION_HORIZONTAL : CombosLibrary.LINER_DIRECTION_VERTICAL
            });
        }.bind(this));
    }

    this.occupiedCells.push({
        x: this.cell.x,
        y: this.cell.y,
        dir: CombosLibrary.LINER_DIRECTION_VERTICAL
    });
    this.occupiedCells.push({
        x: this.cell.x,
        y: this.cell.y,
        dir: CombosLibrary.LINER_DIRECTION_HORIZONTAL
    });

    this.onSpawn = function () {};
};

SuperLinerComponent.prototype.animate = function (type, params) {
    this.onSpawn(this.occupiedCells, type, params);
};
