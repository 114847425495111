/**
 * Created by slava on 02.02.17.
 */

var ComboCellView = BaseCellView.extend({
    ctor: function (cell) {
        this._super();

        if (cell.findComponent(SuperLinerComponent)) {
            this.initialize(cell);
            return;
        }

        var type;
        var skin;
        switch (cell.findComponent(ComboComponent).getType()) {
            case CombosLibrary.TYPE_PLANE:
                type = "plane";
                skin = "plane_plane";
                break;
            case CombosLibrary.TYPE_LINER:
                type = "rocket";
                break;
            case CombosLibrary.TYPE_BOMB:
                type = "bomb";
                break;
        }

        this.animation = new cleverapps.Spine(bundles.game.jsons[type + "_combo_spine_json"]);
        if (skin) {
            this.animation.setSkin(skin);
        }
        this.animation.setPosition(this.width / 2, this.height / 2);
        this.animation.setAnimation(0, "idle", true);
        this.addChild(this.animation, -1);

        if (cell.algo.dir === CombosLibrary.LINER_DIRECTION_VERTICAL) {
            this.animation.setRotation(-90);
        }

        this.initialize(cell);
    },

    createAnimation: function (params) {
        params = params || {};

        if (!this.animation) {
            return;
        }

        if (!params.silent) {
            this.animation.setAnimationAndIdleAfter("open", "idle");
        } else {
            this.animation.setAnimation(0, "idle", true);
        }

        cleverapps.audio.playSound(bundles.game.urls.booster_5_show_effect);
    },

    createFromCenterAnimation: function () {
        if (this.animation) {
            this.animation.setAnimation(0, "idle", true);
        }

        this._super.apply(this, arguments);
    },

    initialize: function (cell) {
        this._super(cell);

        cell.algoEffectAnimation = this.animateEffect.bind(this);

        var comboComponent = cell.findComponent(ComboComponent);
        comboComponent.view.afterInitialize(cell);
    },

    unuse: function () {
        this.cell.algoEffectAnimation = function () {};

        var comboComponent = this.cell.findComponent(ComboComponent);
        comboComponent.view.beforeUnuse();

        this._super();
    },

    reuse: function (cell) {
        this._super(cell);

        if (this.animation) {
            this.animation.setAnimation(0, "idle", true);
        }
    },

    effectSound: function () {
        cleverapps.audio.playSound(bundles.game.urls.multicolor_use_effect);
    },

    animateEffect: function () {
        if (this.cell.algo.bigZOrder) {
            this.setLocalZOrder(20);
        } else {
            this.setLocalZOrder(3);
        }

        if (this.cell.algo.noEffect && this.animation) {
            this.animation.runAction(new cc.RemoveSelf());
            return;
        }

        if (this.cell.findComponent(SuperLinerComponent)) {
            this.cell.findComponent(SuperLinerComponent).view.animateEffect();
        }

        if (this.animation) {
            this.animation.setAnimation(0, "out", false);
            this.animation.setCompleteListenerRemove();
        }
    },

    startExploding: function (goal, idInGroup, params) {
        if (this.cell.findComponent(GoalCoefComponent)) {
            GoalColorCellView.prototype.startExploding.call(this, goal, idInGroup, params);
        } else {
            ColorCellView.prototype.startExploding.call(this, goal, idInGroup, params);
        }
    }
});

cleverapps.styles.ComboCellView = {
    shootBeginUp: 150
};