/**
 * Created by slava on 15/8/19
 */

var CrazyCombosLibrary = {
    merge: function (cells, mergeConstructor, callback) {
        var shape = new Shape(cells);

        var duration = this.getMergeDuration();

        var center = shape.getCenter();
        shape.merge({
            target: center,
            duration: duration
        });

        var superCell = mergeConstructor(center);

        Game.currentGame.counter.setTimeout(function () {
            cells.forEach(function (cell) {
                cell.delete();
            });

            Game.currentGame.field.addCell(superCell);
            superCell.animate(BaseCell.ANIMATION_CREATE);

            Game.currentGame.counter.setTimeout(function () {
                callback(superCell);
            }, superCell.beforeExplodeTimeout() * 1000);
        }, duration * 1000);
    },

    getMergeDuration: function () {
        return 0.2;
    },

    multicolorSimple: function (multicolor, other) {
        multicolor.crazyActionSimple(other);
    },

    multicolorCombo: function (multicolor, other) {
        if (other instanceof ComboCell) {
            var shape = new Shape([multicolor, other]);

            var duration = this.getMergeDuration();

            var center = shape.getCenter();
            shape.merge({
                target: center,
                duration: duration
            });

            Game.currentGame.counter.setTimeout(function () {
                other.delete();
                Game.currentGame.field.removeCell(other.x, other.y);

                multicolor.crazyActionDuplicateCombos(other, function () {
                    var combo = new ComboCell(multicolor.x, multicolor.y, {
                        algo: other.algo
                    });
                    Game.currentGame.field.addCell(combo);
                    combo.animate(BaseCell.ANIMATION_SHOWUP, { silent: true });
                    combo.explode();
                });
            }, duration * 1000);
        } else {
            multicolor.crazyActionDuplicateCombos(other);
        }
    },

    multicolorTwo: function (start, finish) {
        CrazyCombosLibrary.merge([start, finish], function (center) {
            return new SuperMultiColorCell(center.x, center.y);
        }, function (superCell) {
            superCell.explode();
        });
    },

    planeTwo: function (start, finish) {
        CrazyCombosLibrary.merge([start, finish], function (center) {
            var key = CombosLibrary.TYPE_PLANE + "_" + CombosLibrary.TYPE_PLANE;
            return new ComboCell(center.x, center.y, {
                algo: Object.assign({}, CombosLibrary.ByShape[Shape.PLANE], CombosLibrary.powerUp[key])
            });
        }, function (superCell) {
            [start, finish].forEach(function (cell) {
                if (!(cell.x === superCell.x && cell.y === superCell.y)) {
                    Game.currentGame.field.removeCell(cell.x, cell.y);
                }
            });

            superCell.explode();
        });
    },

    planeLiner: function (start, finish) {
        var cargo;

        [start, finish].forEach(function (cell) {
            if (cell.algo.name === CombosLibrary.TYPE_LINER) {
                cargo = cell.algo;
            }
        });

        CrazyCombosLibrary.merge([start, finish], function (center) {
            var key = CombosLibrary.TYPE_PLANE + "_" + CombosLibrary.TYPE_LINER;
            return new ComboCell(center.x, center.y, {
                algo: Object.assign({ cargo: cargo }, CombosLibrary.ByShape[Shape.PLANE], CombosLibrary.powerUp[key])
            });
        }, function (superCell) {
            [start, finish].forEach(function (cell) {
                if (!(cell.x === superCell.x && cell.y === superCell.y)) {
                    Game.currentGame.field.removeCell(cell.x, cell.y);
                }
            });

            superCell.explode();
        });
    },

    planeBomb: function (start, finish) {
        var cargo;

        [start, finish].forEach(function (cell) {
            if (cell.algo.name === CombosLibrary.TYPE_BOMB) {
                cargo = cell.algo;
            }
        });

        CrazyCombosLibrary.merge([start, finish], function (center) {
            var key = CombosLibrary.TYPE_PLANE + "_" + CombosLibrary.TYPE_BOMB;
            return new ComboCell(center.x, center.y, {
                algo: Object.assign({ cargo: cargo }, CombosLibrary.ByShape[Shape.PLANE], CombosLibrary.powerUp[key])
            });
        }, function (superCell) {
            [start, finish].forEach(function (cell) {
                if (!(cell.x === superCell.x && cell.y === superCell.y)) {
                    Game.currentGame.field.removeCell(cell.x, cell.y);
                }
            });

            superCell.explode();
        });
    },

    linerTwo: function (start, finish) {
        CrazyCombosLibrary.merge([start, finish], function (center) {
            var key = CombosLibrary.TYPE_LINER + "_" + CombosLibrary.TYPE_LINER;
            var comboCell = new ComboCell(center.x, center.y, {
                algo: Object.assign({ name: CombosLibrary.TYPE_LINER }, CombosLibrary.Liner, CombosLibrary.powerUp[key])
            });
            comboCell.addComponent(SuperLinerComponent);
            return comboCell;
        }, function (superCell) {
            [start, finish].forEach(function (cell) {
                if (!(cell.x === superCell.x && cell.y === superCell.y)) {
                    Game.currentGame.field.removeCell(cell.x, cell.y);
                }
            });

            superCell.explode();
        });
    },

    linerBomb: function (start, finish) {
        CrazyCombosLibrary.merge([start, finish], function (center) {
            var key = CombosLibrary.TYPE_LINER + "_" + CombosLibrary.TYPE_BOMB;
            var comboCell = new ComboCell(center.x, center.y, {
                algo: Object.assign({ name: CombosLibrary.TYPE_SUPER_LINER }, CombosLibrary.Liner, CombosLibrary.powerUp[key])
            });
            comboCell.addComponent(SuperLinerComponent);
            return comboCell;
        }, function (superCell) {
            [start, finish].forEach(function (cell) {
                if (!(cell.x === superCell.x && cell.y === superCell.y)) {
                    Game.currentGame.field.removeCell(cell.x, cell.y);
                }
            });

            superCell.explode();
        });
    },

    bombBomb: function (start, finish) {
        CrazyCombosLibrary.merge([start, finish], function (center) {
            var key = CombosLibrary.TYPE_BOMB + "_" + CombosLibrary.TYPE_BOMB;
            return new ComboCell(center.x, center.y, {
                algo: Object.assign({}, CombosLibrary.ByShape[Shape.BOMB], CombosLibrary.powerUp[key])
            });
        }, function (superCell) {
            [start, finish].forEach(function (cell) {
                if (!(cell.x === superCell.x && cell.y === superCell.y)) {
                    Game.currentGame.field.removeCell(cell.x, cell.y);
                }
            });

            superCell.explode();
        });
    },

    comboCombo: function (start, finish) {
        if (Match3Rules.Heroes) {
            if (Game.currentGame && !Game.currentGame.field.isThreeInRow(start.y, start.x)) {
                start.mustHurtNextBurn = true;
            }

            if (Game.currentGame && !Game.currentGame.field.isThreeInRow(finish.y, finish.x)) {
                finish.mustHurtNextBurn = true;
            }

            return;
        }

        var match = function (typeA, typeB) {
            return start.algo.name === typeA && finish.algo.name === typeB || start.algo.name === typeB && finish.algo.name === typeA;
        };

        if (match(CombosLibrary.TYPE_PLANE, CombosLibrary.TYPE_PLANE)) {
            CrazyCombosLibrary.planeTwo(start, finish);
        } else if (match(CombosLibrary.TYPE_PLANE, CombosLibrary.TYPE_LINER)) {
            CrazyCombosLibrary.planeLiner(start, finish);
        } else if (match(CombosLibrary.TYPE_PLANE, CombosLibrary.TYPE_BOMB)) {
            CrazyCombosLibrary.planeBomb(start, finish);
        } else if (match(CombosLibrary.TYPE_LINER, CombosLibrary.TYPE_LINER)) {
            CrazyCombosLibrary.linerTwo(start, finish);
        } else if (match(CombosLibrary.TYPE_LINER, CombosLibrary.TYPE_BOMB)) {
            CrazyCombosLibrary.linerBomb(start, finish);
        } else if (match(CombosLibrary.TYPE_BOMB, CombosLibrary.TYPE_BOMB)) {
            CrazyCombosLibrary.bombBomb(start, finish);
        }
    },

    colorCombo: function (color, combo) {
        if (Match3Rules.Heroes) {
            return;
        }

        combo.mustHurtNextBurn = true;
    }
};

var CrazyCombos = function (start, finish) {
    if (start instanceof MultiColorCell || finish instanceof MultiColorCell) {
        if (start instanceof MultiColorCell && finish instanceof MultiColorCell) {
            CrazyCombosLibrary.multicolorTwo(start, finish);
        } else {
            var multicolor = start;
            var other = finish;

            if (finish instanceof MultiColorCell) {
                multicolor = finish;
                other = start;
            }

            if (other.findComponent(ComboComponent)) {
                CrazyCombosLibrary.multicolorCombo(multicolor, other);
            } else {
                CrazyCombosLibrary.multicolorSimple(multicolor, other);
            }
        }
    } else if (start.findComponent(ComboComponent) && finish.findComponent(ComboComponent)) {
        CrazyCombosLibrary.comboCombo(start, finish);
    } else if (start.findComponent(ComboComponent) && finish.findComponent(ColorComponent)) {
        CrazyCombosLibrary.colorCombo(finish, start);
    } else if (finish.findComponent(ComboComponent) && start.findComponent(ColorComponent)) {
        CrazyCombosLibrary.colorCombo(start, finish);
    }
};