/**
 * Created by Denis Kuzin on 27 november 2024
 */

var SuperLinerComponentView = cc.Node.extend({
    ctor: function (component, cellView) {
        this._super();

        this.component = component;
        this.cellView = cellView;

        this.setContentSize(cellView.getContentSize());

        this.animations = [];

        component.onSpawn = this.createListener(this.createAnimation.bind(this));
    },

    createAnimation: function (occupiedCells, type, params) {
        params = params || {};

        var fieldView = Game.currentGame.field.onGetView();
        occupiedCells.forEach(function (cell) {
            var position = this.cellView.convertToNodeSpace(fieldView.convertToWorldSpace(BaseCellView.alignInTheGrid(cell.x, cell.y)));
            var animation = new cleverapps.Spine(bundles.game.jsons["rocket_combo_spine_json"]);
            animation.setPosition(position);

            if (!params.silent) {
                animation.setAnimationAndIdleAfter("open", "idle");
            } else {
                animation.setAnimation(0, "idle", true);
            }

            if (cell.dir === CombosLibrary.LINER_DIRECTION_VERTICAL) {
                animation.setRotation(-90);
            }

            this.addChild(animation);
            this.animations.push(animation);
        }.bind(this));
    },

    animateEffect: function () {
        this.animations.forEach(function (animation) {
            animation.setAnimation(0, "out", false);
            animation.setCompleteListenerRemove();
        });
    }
});