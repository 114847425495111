/**
 * Created by Vladislav on 18.09.2024.
 */

cleverapps.InitByType["match3"] = function () {
    match3.heroes = new Heroes();

    match3.levelAttempts = new LevelAttempts();

    PlaySession.EndSessionListeners.push(Match3HelpByAttemptsReporter);
};