/**
 * Created by anatoly on 14.11.2024
 */

var ReceivedCalendarTileComponent = cc.Node.extend({
    ctor: function () {
        this._super();

        this.createMarks();
    },

    createMarks: function () {
        var styles = cleverapps.styles.PrizeCalendarTileView;

        this.mark = new cleverapps.Spine(bundles.prizecalendar.jsons.check_json);
        this.mark.setAnimationAndIdleAfter("popup", "idle");
        this.mark.setPositionRound(styles.mark);
        this.addChild(this.mark);
    }
});