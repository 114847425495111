var DogCell = function (x, y) {
    BaseCell.call(this, x, y);

    this.addComponent(EaterComponent, { cellClass: DogCell });
    this.addComponent(LiveLineComponent);

    this.hurtable = false;
};

DogCell.prototype = Object.create(BaseCell.prototype);
DogCell.prototype.constructor = DogCell;

DogCell.prototype.bundleId = function () {
    return "dog";
};

DogCell.prototype.getViewClass = function () {
    return EaterCellView;
};

DogCell.prototype.getGoalId = function () {
    return DogCell.GOAL_ID;
};

DogCell.prototype.getAcceptedColors = function () {
    return DogCell.ACCEPTED_COLORS;
};

DogCell.GOAL_ID = "dog_cell";

DogCell.ACCEPTED_COLORS = ["e"];