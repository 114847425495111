/**
 * Created by Denis Kuzin on 12 august 2024
 */

var ComboComponentView = cc.Node.extend({
    ctor: function (component, cellView) {
        this._super();

        this.component = component;
        this.cellView = cellView;
    },

    afterInitialize: function () {
        this.component.cell.algoShotAnimation = this.animateShot.bind(this);
    },

    beforeUnuse: function () {
        this.component.cell.algoShotAnimation = function () {};
    },

    targetBlast: function (targetPosition) {
        if (!this.cellView.isRunning() || !this.component.cell || !this.component.cell.algo) {
            return;
        }

        var animation = this.component.cell.algo.blast_animation;

        var blastAnimation = BaseAnimation.factory(animation);
        blastAnimation.setLocalZOrder(22);
        blastAnimation.setPosition(targetPosition);
        this.cellView.parent.addChild(blastAnimation);
        blastAnimation.runAnimate("animation");
        return blastAnimation;
    },

    shots: {
        bezier: function (target, shootDuration) {
            var beginPosition = this.cellView.getPosition();
            var targetPosition = BaseCellView.alignInTheGrid(target.x, target.y);

            var first = cc.p(beginPosition.x, Math.max(beginPosition.y, targetPosition.y) + cleverapps.styles.ComboCellView.shootBeginUp);
            var second = cc.p(targetPosition.x, first.y);
            var rotateAngle = targetPosition.x < beginPosition.x ? -180 : 180;
            var blast = new cc.Sprite(bundles.game.frames.bomb_png);

            blast.setLocalZOrder(21);
            blast.setPosition(beginPosition);
            blast.setScale(0.3);
            this.cellView.parent.addChild(blast);
            blast.runAction(new cc.Sequence(
                new cc.Spawn(
                    new cc.BezierTo(shootDuration, [first, second, targetPosition]).easing(cc.easeIn(1)),
                    new cc.RotateBy(shootDuration, rotateAngle),
                    new cc.ScaleTo(shootDuration / 2, 1)
                ),
                new cc.CallFunc(function () {
                    this.targetBlast(targetPosition);
                }.bind(this)),
                new cc.RemoveSelf()
            ));
        },

        plane: function (target, shootDuration, order) {
            var delay = 0.6;

            if (order === 0) {
                var explosion = new cleverapps.Spine(bundles.game.jsons.plane_combo_spine_json);
                explosion.setAnimation(0, "start", false);
                explosion.setCompleteListenerRemove();
                explosion.setPositionRound(BaseCellView.alignInTheGrid(this.component.cell.x, this.component.cell.y));
                this.cellView.parent.addChild(explosion);
                explosion.setLocalZOrder(this.cellView.getLocalZOrder() - 1);
            }

            var skin = "plane_plane";
            var cargo = this.component.cell.algo.cargo;
            if (cargo) {
                if (cargo.name === CombosLibrary.TYPE_LINER) {
                    skin = "plane_linear";
                } else if (cargo.name === CombosLibrary.TYPE_BOMB) {
                    skin = "plane_tnt";
                }
            }

            var animation = new cleverapps.Spine(bundles.game.jsons.plane_combo_spine_json);
            animation.setSkin(skin);
            animation.setAnimation(0, "ignite", false);

            var rocket = new Rocket(this.component.cell, target, {
                delay: delay,
                duration: shootDuration - delay,
                json: this.component.cell.algo.shoot_animation,
                rocket: animation,
                plane: true,
                onLaunch: function () {
                    animation.setAnimation(0, "fly", true);
                    cleverapps.audio.playSound(bundles.game.urls.create_multicolor_effect);
                },
                onArrive: function (callback) {
                    cleverapps.audio.playSound(bundles.game.urls.boom_effect);

                    animation.replaceParentSamePlace(cleverapps.scenes.getRunningScene(), { keepScale: true });
                    animation.setLocalZOrder(11);
                    animation.setAnimation(0, "splash", false);
                    animation.setCompleteListenerRemove(callback);
                    animation.setRotation(0);
                }
            });
            rocket.setVisible(true);
            this.cellView.parent.addChild(rocket);
            rocket.setLocalZOrder(21);
        },

        rocket: function (target, shootDuration) {
            var rocket = SimpleRocket(this.component.cell, target, {
                delay: 0.2,
                duration: shootDuration,
                json: this.component.cell.algo.shoot_animation
            });
            this.cellView.parent.addChild(rocket);
            rocket.setLocalZOrder(21);
        },

        blast: function (target) {
            var targetPosition = BaseCellView.alignInTheGrid(target.x, target.y);
            this.targetBlast(targetPosition);
        },

        jump: function (target, shootDuration) {
            var targetPosition = BaseCellView.alignInTheGrid(target.x, target.y);
            var goalCoefComponent = this.component.cell.findComponent(GoalCoefComponent);
            var coefView = goalCoefComponent && goalCoefComponent.view;

            shootDuration *= 0.7;
            if (coefView) {
                coefView.setVisible(false);
            }
            this.cellView.setLocalZOrder(22);
            this.cellView.runAction(new cc.Sequence(
                new cc.MoveTo(shootDuration, targetPosition),
                new cc.CallFunc(function () {
                    if (Game.currentGame) {
                        Game.currentGame.shakeField({ delay: 150 });
                    }
                    if (coefView) {
                        coefView.setVisible(true);
                    }
                })
            ));
        },

        throw: function (target, shootDuration) {
            var beginPosition = this.cellView.getPosition();
            var targetPosition;
            if (this.component.cell.algo.getTargetPosition) {
                targetPosition = this.component.cell.algo.getTargetPosition(target, beginPosition);
            } else {
                targetPosition = BaseCellView.alignInTheGrid(target.x, target.y);
            }
            var goalCoefComponent = this.component.cell.findComponent(GoalCoefComponent);
            var coefView = goalCoefComponent && goalCoefComponent.view;

            shootDuration *= 0.7;
            if (coefView) {
                coefView.setVisible(false);
            }
            var blast = this.targetBlast(beginPosition);
            if (this.component.cell.algo.rotateToTarget) {
                blast.setRotation(Math.atan2(targetPosition.x - beginPosition.x, targetPosition.y - beginPosition.y) * 180 / Math.PI);
            }
            if (blast) {
                var moveAction = new cc.MoveTo(shootDuration, targetPosition);
                if (this.component.cell.algo.applyEasing) {
                    this.component.cell.algo.applyEasing(moveAction);
                }
                var blastExpldeAnimationName = this.component.cell.algo.blast_explde_animation_name;
                blast.runAction(new cc.Sequence(
                    moveAction,
                    new cc.CallFunc(function () {
                        if (Game.currentGame) {
                            Game.currentGame.shakeField({ delay: 150 });
                        }
                        if (coefView) {
                            coefView.setVisible(true);
                        }
                        if (blastExpldeAnimationName) {
                            blast.runAnimate(blastExpldeAnimationName);
                        }
                    })
                ));
            }
        },

        ray: function (target, shootDuration) {
            var start = this.cellView.getPosition();
            var targetPos = BaseCellView.alignInTheGrid(target.x, target.y);

            var animation = new cleverapps.Spine(this.component.cell.algo.shoot_animation);
            animation.setAnimation(0, "idle", true);

            var ray = new cc.Node();
            ray.setAnchorPoint(0.5, 0);
            ray.setContentSize2(animation.getContentSize());
            ray.addChild(animation);
            animation.setPositionRound(ray.width / 2, ray.height / 2);
            this.cellView.parent.addChild(ray);
            ray.setLocalZOrder(21);
            ray.setPositionRound(start);
            ray.setRotation(Math.atan2(targetPos.x - start.x, targetPos.y - start.y) * 180 / Math.PI);

            var dist = Math.sqrt((targetPos.x - start.x) * (targetPos.x - start.x) + (targetPos.y - start.y) * (targetPos.y - start.y));
            var scaleY = dist / ray.height;
            ray.setScaleY(0);
            ray.runAction(new cc.Sequence(
                new cc.ScaleTo(shootDuration, 1, scaleY),
                new cc.CallFunc(function () {
                    animation.setAnimation(0, "finish", false);
                    animation.setCompleteListenerRemove();
                })
            ));
        },

        none: function () {
        }
    },

    animateShot: function (target, shootDuration, order) {
        var shootAlgorithm = this.shots[this.component.cell.algo.shootAlgorithm];
        shootAlgorithm.call(this, target, shootDuration, order);
    }
});