/**
 * Created by slava on 02.02.17.
 */

var ComboCell = function (x, y, options) {
    BaseCell.call(this, x, y);

    this.algo = options.algo;

    this.addComponent(ComboComponent);

    this.movable = true;
    this.putInPoolAvailable = false;
    this.explodeOnGameFinish = true;
};

ComboCell.prototype = Object.create(BaseCell.prototype);
ComboCell.prototype.constructor = ComboCell;

ComboCell.prototype.save = function () {
    return ComboCell.CODES.concat([this.algo.name[0], (this.algo.dir === undefined) ? 0 : this.algo.dir]);
};

ComboCell.prototype.load = function (data) {
    switch (data[1]) {
        case CombosLibrary.TYPE_PLANE[0]:
            this.algo = CombosLibrary.ByShape[Shape.PLANE];
            break;
        case CombosLibrary.TYPE_LINER[0]:
            var dir = parseInt(data[2]);
            if (dir === CombosLibrary.LINER_DIRECTION_HORIZONTAL) {
                this.algo = CombosLibrary.ByShape[Shape.FOUR_H];
            } else if (dir === CombosLibrary.LINER_DIRECTION_VERTICAL) {
                this.algo = CombosLibrary.ByShape[Shape.FOUR_V];
            }
            break;
        case CombosLibrary.TYPE_BOMB[0]:
            this.algo = CombosLibrary.ByShape[Shape.BOMB];
            break;
    }
};

ComboCell.prototype.getViewClass = function () {
    return ComboCellView;
};

ComboCell.prototype.repaint = function () {
    return this.findComponent(ComboComponent).repaint();
};

ComboCell.prototype.getRandomColor = function () {
    return undefined;
};

ComboCell.prototype.canMoveWith = function (neighbor) {
    var colorComponent = neighbor.findComponent(ColorComponent);
    return (neighbor instanceof ComboCell) || (neighbor instanceof MultiColorCell) || colorComponent;
};

ComboCell.prototype.explode = function (params) {
    if (!this.alive) {
        return;
    }

    this.alive = false;

    var comboComponent = this.findComponent(ComboComponent);
    if (!params || !params.callback) {
        comboComponent.algoEffect(this.afterExplode.bind(this));
    } else {
        comboComponent.algoEffect(this.afterExplode.bind(this, params.callback));
    }
};

ComboCell.prototype.onDoubleTouch = function () {
    if (Game.currentGame) {
        Game.currentGame.field.removeSelection();
        Game.currentGame.field.trigger("move");
        this.hurt();
    }
};

ComboCell.prototype.beforeExplodeTimeout = function () {
    return 0.3;
};
