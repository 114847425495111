/**
 * Created by vladislav on 01.02.2022
 */

cleverapps.overrideStyles(cleverapps.styles.Decorators, {
    "present": {
        type: Decors.TYPE_IMAGE,
        image: "decor_shop_png",
        scale: 1.1,
        zOrder: 1,
        x: { align: "left", dx: 60 },
        y: { align: "top", dy: 110 }
    },

    "star": {
        type: Decors.TYPE_IMAGE,
        image: "decor_league_png",
        scale: 1.1,
        zOrder: 1,
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 150 }
    },

    "xmas_window_right_top": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window,
        animation: "up_right",
        x: { align: "right", dx: 30 },
        y: { align: "top", dy: 70 },
        lovesBackgroundAndForeground: true
    },

    "xmas_window_right_bottom": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window_title,
        animation: "right",
        x: { align: "right", dx: -2 },
        y: { align: "bottom", dy: 11 },
        lovesBackgroundAndForeground: true
    },

    "xmas_window_left_bottom": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window_title,
        animation: "left",
        x: { align: "left", dx: 4 },
        y: { align: "bottom", dy: 11 },
        lovesBackgroundAndForeground: true
    },

    "xmas_window_left_top": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window,
        animation: "up_left",
        x: { align: "left", dx: -30 },
        y: { align: "top", dy: 70 },
        lovesBackgroundAndForeground: true
    },

    "xmas_window_top": {
        type: Decors.TYPE_SCALE9,
        image: bundles.windows.frames.xmas_window_top,
        lovesBackgroundAndForeground: true,
        scale: 1.0,
        zOrder: 1,
        width: "parent",
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 15 }
    },

    "halloween_window_candle_left": {
        image: bundles.windows.frames.candle_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "right", dx: 20 },
        y: { align: "bottom", dy: -11 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_lian_left": {
        image: bundles.windows.frames.lian_left_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "left", dx: -23 },
        y: { align: "center", dy: 10 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_lian_right": {
        image: bundles.windows.frames.lian_down_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "right", dx: 23 },
        y: { align: "center", dy: 40 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_lian_bottom": {
        image: bundles.windows.frames.lian_right_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "left", dx: -6 },
        y: { align: "top", dy: 0 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_decor_bg_right": {
        image: bundles.windows.frames.decor_bg,
        type: Decors.TYPE_IMAGE,
        x: { align: "right", dx: 6 },
        y: { align: "top", dy: 0 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_pumpkin": {
        image: bundles.windows.frames.pumpkin_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "right", dx: 42 },
        y: { align: "top", dy: 44 },
        lovesTitle: "bg_window",
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_spider": {
        image: bundles.windows.frames.spider_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "left", dx: -20 },
        y: { align: "bottom", dy: 1 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SceneDecors, {
    xmas_menubar: {
        image: bundles.menubar.frames.xmas_menubar,
        x: { align: "left", dx: 50 },
        y: { align: "top", dy: 15 }
    },

    xmas_menubar_level: {
        image: bundles.menubar.frames.xmas_menubar_level,
        x: { align: "left", dx: 60 },
        y: { align: "top", dy: -7 }
    },

    halloween_menubar: {
        image: bundles.menubar.frames.spiderweb_decor,
        x: { align: "right", dx: 12 },
        y: { align: "top", dy: 18 }
    }
});
