/**
 * Created by Oleg on 08.08.2019.
 */

var BlanketCell = function (x, y, rows, cols) { // it is not supported to be in "goals", goals counter increment does not work + fly to goal animation is missed
    BaseCell.call(this, x, y);

    this.addComponent(EaterComponent, { cellClass: BlanketCell, rows: rows, cols: cols });
    this.addComponent(LiveLineComponent);
    var bigComponent = this.bigComponent = this.findComponent(BigComponent);

    this.hurtable = false;

    // default value needed to be set up, because it is eater cell
    this.setAcceptedColors([BlanketCell.DEFAULT_ACCEPTED_COLOR]);

    // need to move through entire field to check "head" flag, not like in bigCells,
    // because their sizes are constant and they just check 2x2, but here only head cell knows the size
    // editor bugs or game fields bugs otherwise (without check)
    if (Game && Game.currentGame && Game.currentGame.field && Game.currentGame.field.cells) {
        for (var hy = 0; hy < Field.SIZE && bigComponent.isHead; hy++) {
            for (var hx = 0; hx < Field.SIZE && bigComponent.isHead; hx++) {
                if (Game.currentGame.field.cells[hy] && Game.currentGame.field.cells[hy][hx] instanceof BlanketCell) {
                    var component = Game.currentGame.field.cells[hy][hx].findComponent(BigComponent);

                    if (component.isHead && x >= hx && y >= hy && x < hx + component.cols && y < hy + component.rows) {
                        bigComponent.isHead = false;
                        bigComponent.head = Game.currentGame.field.cells[hy][hx];
                        if (Game.currentGame.EATERS) {
                            Game.currentGame.EATERS = Game.currentGame.EATERS.filter(function (element) {
                                return !((element instanceof BlanketCell) && (element.x === x) && (element.y === y));
                            });
                        }
                    }
                }
            }
        }
    }

    // decorator code
    this.hideInnerCell = true;
    this.deleteView = function () {
    };
};

BlanketCell.prototype = Object.create(BaseCell.prototype);
BlanketCell.prototype.constructor = BlanketCell;

BlanketCell.prototype.checkHeadFlag = function () {
};

BlanketCell.prototype.resizeBeforeViewCreated = function (rows, cols) {
    if (!this.bigComponent.isHead) {
        return;
    }
    var bigComponent = this.findComponent(BigComponent);
    bigComponent.rows = rows;
    bigComponent.cols = cols;
};

BlanketCell.prototype.getCenterVector = function () {
    var bigComponent = this.findComponent(BigComponent);
    return {
        x: cleverapps.styles.BaseCellView.CELL_SIZE_PX * (bigComponent.cols - 1) / 2,
        y: -cleverapps.styles.BaseCellView.CELL_SIZE_PX * (bigComponent.rows - 1) / 2
    };
};

BlanketCell.prototype.getGoalTargetPosition = function (basePosition) {
    var center = this.getCenterVector();
    basePosition.x -= center.x;
    basePosition.y -= center.y;
    return basePosition;
};

BlanketCell.prototype.load = function (data, loader) {
    if (data.length > 2) {
        if (data[1] !== BlanketCell.SEPARATOR) {
            this.resizeBeforeViewCreated(parseInt(data[1], 10), parseInt(data[3], 10)); // resize to parsed rows & cols
            this.setAcceptedColors([data[2]]);
            this.lives = parseInt(data.slice().splice(4).join(""));
            this.setInnerCell(loader.loadNext(this.y, this.x, data.splice(4 + this.lives.toString().length + 1))); // decorator code
        } else {
            this.setInnerCell(loader.loadNext(this.y, this.x, data.splice(2))); // decorator code
        }
    }
};

BlanketCell.prototype.save = function () {
    var code = BlanketCell.SEPARATOR;
    if (this.bigComponent.isHead) {
        code = "" + this.bigComponent.rows + this.getAcceptedColors().join("") + this.bigComponent.cols + "" + this.lives + BlanketCell.SEPARATOR;
    }
    var res = BlanketCell.CODES.concat(code.split(""));
    return res.concat(this.innerCell.save());
};

BlanketCell.prototype.explodeDuration = function () {
    return 0.1;
};

BlanketCell.prototype.explode = function () {
    if (this.bigComponent.isHead) {
        if (this.bigComponent.headExploded || !Game.currentGame) {
            return;
        }
        this.bigComponent.headExploded = true;

        var cells = Game.currentGame.field.cells;
        for (var dy = 0; dy < this.bigComponent.rows; dy++) {
            for (var dx = 0; dx < this.bigComponent.cols; dx++) {
                if (cells[this.y + dy][this.x + dx].innerCell) {
                    var cell = cells[this.y + dy][this.x + dx].innerCell;
                    cells[this.y + dy][this.x + dx].setInnerCell(undefined);
                    if (cell.findComponent(BigComponent)) { // when BigCell is only loaded via load() the head flag is always "true", there are no such cells on field at that moment
                        cell.checkHeadFlag();
                    }
                    Game.currentGame.field.addCell(cell);
                    if (!cell.visible) {
                        cell.changeVisible(true);
                    }
                }
            }
        }

        Game.currentGame.counter.setTimeout(function () {
            for (var dy = 0; dy < this.bigComponent.rows; dy++) {
                for (var dx = 0; dx < this.bigComponent.cols; dx++) {
                    if (cells[this.y + dy][this.x + dx]) {
                        var cell = cells[this.y + dy][this.x + dx];
                        cell.animate(BaseCell.ANIMATION_BOOM_NEIGHBOUR);
                    }
                }
            }
        }.bind(this), this.explodeDuration() * 1000);

        Game.currentGame.counter.setTimeout(function () {}, this.explodeDuration() * 1000);

        this.deleteView();
    }
};

BlanketCell.prototype.afterExplode = function () { // to prevent creating the "head" cell again after deleting the blanket with explodeViaCollect
};

BlanketCell.prototype.bundleId = function () {
    return "blanket";
};

BlanketCell.prototype.getViewClass = function () {
    return BlanketCellView;
};

BlanketCell.prototype.getGoalId = function () {
    return BlanketCell.GOAL_ID;
};

BlanketCell.prototype.setAcceptedColors = function (acceptedColors) {
    if (this.bigComponent.isHead) {
        this.acceptedColors = acceptedColors;
    }
};

BlanketCell.prototype.getAcceptedColors = function () {
    return this.acceptedColors;
};

BlanketCell.prototype.editorComponents = function () {
    if (this.bigComponent.isHead) {
        return [BaseCellComponent, BlanketCellComponentCreator, LiveLineEditorComponent];
    }
    return undefined;
};

BlanketCell.SEPARATOR = ">";
BlanketCell.GOAL_ID = "blanket_cell";
BlanketCell.DEFAULT_ACCEPTED_COLOR = "d";
