/**
 * Created by anatoly on 07.11.2024
 */

var PrizeCalendarTile = function (options) {
    this.isLast = options.isLast;
    this.price = options.config.hard || 0;
    this.rewardsList = new RewardsList(options.config.reward, { event: cleverapps.EVENTS.EARN.MISSION });

    this.onClick = options.onClick;
    this.onDoubleClick = options.onDoubleClick;

    this.onReceiveTile = function () {};
    this.onChangeState = function () {};
    this.onOpenTile = function () {};
};

PrizeCalendarTile.prototype.getComponent = function () {
    var ComponentClass;

    switch (this.state) {
        case (PrizeCalendarTile.STATE_WAITING):
            ComponentClass = ActiveCalendarTileComponent;
            break;
        case (PrizeCalendarTile.STATE_ACTIVE):
            ComponentClass = ActiveCalendarTileComponent;
            break;
        case (PrizeCalendarTile.STATE_LOCKED):
            ComponentClass = LockedCalendarTileComponent;
            break;
        case (PrizeCalendarTile.STATE_RECEIVED):
            ComponentClass = ReceivedCalendarTileComponent;
            break;
    }

    return new ComponentClass(this);
};

PrizeCalendarTile.prototype.onClaimTile = function (callback) {
    this.rewardsList.receiveRewards();

    this.onReceiveTile(this.rewardsList, function () {
        this.onChangeState();
        callback();
    }.bind(this));
};

PrizeCalendarTile.prototype.onTileDoubleClick = function () {
    if (this.state === PrizeCalendarTile.STATE_WAITING) {
        this.onDoubleClick();
        this.countDown.resetTimeLeft(0);
    }
};

PrizeCalendarTile.prototype.changeState = function (newState) {
    this.state = newState;
};

PrizeCalendarTile.prototype.open = function () {
    this.onOpenTile(this.onChangeState.bind(this));
};

PrizeCalendarTile.prototype.setTimer = function (duration) {
    this.countDown = new cleverapps.CountDown(duration, {
        onFinish: function () {
            this.state = PrizeCalendarTile.STATE_ACTIVE;
            this.onChangeState();
        }.bind(this)
    });
};

PrizeCalendarTile.STATE_LOCKED = 0;
PrizeCalendarTile.STATE_ACTIVE = 1;
PrizeCalendarTile.STATE_WAITING = 2;
PrizeCalendarTile.STATE_RECEIVED = 3;
