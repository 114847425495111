/**
 * Created by mac on 7/22/17.
 */

var Shape = function (cells) {
    this.cells = cells || [];

    this.minRow = Field.SIZE;
    this.maxRow = 0;
    this.minCol = Field.SIZE;
    this.maxCol = 0;
};

Shape.prototype.merge = function (params) {
    this.cells.forEach(function (cell) {
        if (params.filter && !params.filter(cell)) {
            return;
        }

        cell.animate(BaseCell.ANIMATION_MOVE_TO, params);
    });
};

Shape.prototype.add = function (cell) {
    this.cells.push(cell);

    if (this.minRow > cell.y) {
        this.minRow = cell.y;
    }
    if (this.maxRow < cell.y) {
        this.maxRow = cell.y;
    }
    if (this.minCol > cell.x) {
        this.minCol = cell.x;
    }
    if (this.maxCol < cell.x) {
        this.maxCol = cell.x;
    }
};

Shape.prototype.calculateForm = function () {
    if (this.form) {
        return this.form;
    }

    this.form = Shape.THREE;

    if (this.maxRow - this.minRow >= 4 || this.maxCol - this.minCol >= 4) {
        this.form = Shape.FIVE;
    } else if (this.maxRow - this.minRow >= 2 && this.maxCol - this.minCol >= 2) {
        this.form = Shape.BOMB;
    } else if (this.maxRow - this.minRow >= 3) {
        this.form = Shape.FOUR_V;
    } else if (this.maxCol - this.minCol >= 3) {
        this.form = Shape.FOUR_H;
    } else if (this.maxRow - this.minRow >= 1 && this.maxCol - this.minCol >= 1) {
        if (Match3Rules.Features.Plane) {
            this.form = Shape.PLANE;
        }
    }

    return this.form;
};

Shape.prototype.getCenter = function () {
    var lastMove = Game.currentGame.field.lastMove;

    var centerX = (this.maxCol + this.minCol) / 2;
    var centerY = (this.maxRow + this.minRow) / 2;

    var bestCell;
    var minDist = Number.MAX_VALUE;

    for (var i = 0; i < this.cells.length; i++) {
        var cell = this.cells[i];
        var dist = Math.abs(cell.y - centerY) + Math.abs(cell.x - centerX);

        if (lastMove) {
            if ((lastMove[0].x === cell.x && lastMove[0].y === cell.y) || (lastMove[1].x === cell.x && lastMove[1].y === cell.y)) {
                dist = -1;
            }
        }

        if (dist < minDist && !cell.innerCell && cell.lives <= 1 && cell.lives >= 0) {
            minDist = dist;
            bestCell = cell;
        }
    }

    return bestCell || this.cells[0];
};

Shape.THREE = ".";
Shape.FIVE = "*";
Shape.BOMB = "+";
Shape.FOUR_V = "|";
Shape.FOUR_H = "-";
Shape.PLANE = ":";